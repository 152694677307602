import React from 'react';

export default function ServiceModal(props) {
  const { name, desc, image, content, onClick } = props || {};
  console.log(`props: ${JSON.stringify(props)}`);

  return (
    <div className="modal fade" id="serviceModal" tabindex="-1" aria-label="serviceModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h1 className="modal-title fs-5 text-center" id="serviceModalLabel">{name}</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => onClick()}
            ></button>
          </div>
          <div className="modal-body row">
            <div><img src={image} className="img-fluid" alt={name} /></div>
            {desc && desc()}
            {content && content()}
          </div>
        </div>
      </div>
    </div>    
  );
};
