import React from "react";
import mainImg from "images/large_tsg_logo.png";

// import { AMAZON_BOOK_LINKS } from "utils/constants";

export default function Home() {
  return (
    <section id="home" className="top-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 shadow py-5 px-5 rounded-4 bg-light bg-gradient bg-opacity-25">
            <p className="d-block mx-auto mb-4">
              <img id="top-logo" className="img-fluid" src={mainImg} alt="Tookes Strategic Group, LLC" />
            </p>
            <p className="fs-2 lh-base text-center">
              Empowering communities, igniting change.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
