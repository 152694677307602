const API_ENDPOINT ="https://u5er4vpoog.execute-api.us-east-1.amazonaws.com/Stage/submitForm";
const SITE = 'tsgone';

/*
    body: {
        emailAddress: "tope.adekanbi@gmail.com"
        fullName: "Tope Adekanb"
        message: "teate"
    }

    "body": {
        "name": "Sender Name",
        "reply_to": "sender@email.com",
        "message": "A test message"
    }

    {
        "name": "Rob Wilde ",
        "reply_to": "rwilde@mayboneclark.com",
        "message": "Hello Henry & Jeffers, \nMy name is Rob Wilde I came across your business through the Hartford Winterfest. I am an insurance agent and I work with publishers, authors and retail book stores. I thought I would reach out and see how you are currently doing with your insurance coverage. I would love to get you a quote and see if we can do better for you. \nLet me know if you are interested. Just take 5 mins and I can email you a quote. \nThanks, \nRob \n"
    }
*/

export const sendDatatoAWS = async (data) => {
    if (data) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
            site: SITE,
            name: data.fullName,
            reply_to: data.emailAddress,
            message: data.message,
        });

        const options = {
            method: 'POST',
            headers,
            body
        };

        return await fetch(API_ENDPOINT, options)
            .then(response => response.text())
            .then(result => {
                console.log('result', result);
                return result;
            })
            .catch(error => {
                console.log('error', error)
                return null;
            });
    } else {
        return null;
    }
}
