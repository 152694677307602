import React from "react";
import authorImg from "images/Tookes_Headshot.jpg";
// import ImaniImg from "images/imani-large@2x.png";

export default function AboutSection() {
  return (
    <section id="about-us" className="ltblue-section">
      <div className="container text-center">
        <p className="h2 display-5 fw-normal text-center">About Us</p>

        <div className="row">
          <div className="col-12 col-md-4 py-4">
            <div className="circle-avatar">
              <img
                className="img-fluid"
                src={authorImg}
                alt="Ryan Tookes"
              />
            </div>
          </div>
          <div className="col-12 col-md-8 text-start">
            <p className="fs-5 lh-base my-4">
              {/* <span className="fw-bold">Lorem Ipsum 1</span><br/> */}
              Tookes Strategic Group, LLC - is a full-service business and political consultancy firm.
            </p>
            <div className="fs-5 lh-base my-4">
              <span className="fw-bold">Our Mission</span>
              <p>Our mission is to create positive change in the world by fostering community engagement, providing expert political consulting, developing innovative relationship mapping strategies, delivering impactful motivational speaking, and offering insightful business consulting services specifically tailored to the energy and political sectors. We strive to empower individuals and organizations to make informed decisions, build strong relationships, and achieve their goals while promoting sustainable practices and responsible leadership. Through our collaborative approach, we aim to inspire action, promote growth, and drive meaningful change for the betterment of society and the environment.</p>
            </div>
            <div className="fs-5 lh-base my-4">
              <span className="fw-bold">President - Biography</span>
              <p>Ryan Tookes is a results and solution focused professional, with the demonstrated ability to navigate and recognize the interrelation of financing, government, law, and public policy. Mr. Tookes has a proven proficiency in strategic plan development, execution, coalition building, and driving opposing groups toward consensus; for the best interest of his clients.</p>
              <p>Tookes previously served as a policy leader in the utility industry. Tookes has worked with multiple Fortune 500 and 100 companies over the course of his career, contributing to the policy initiatives of the respective companies. Further, Tookes has successfully led initiatives in the District of Columbia, Maryland, Delaware, and New Jersey.</p>
              <p>Tookes, a native of Newark, NJ is a graduate of Wesleyan University. He holds both a Masters and Juris Doctor from Rutgers-Camden and completed the National Association of Regulatory Commissioners-Rate School. He was named top 20 under 40 by South Jersey Biz magazine, recognizing top leaders in the State. He also studied in Moscow and St. Petersburg, Russia as part of his Master's thesis and is conversationally fluent in Spanish.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
