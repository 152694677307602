import React, { useState } from "react";
import ServiceModal from "components/shared/ServiceModal";

// import placeHolder from "images/tsg_one_logo_small.png";
import communityEngagementIcon from "images/services_community_engagement.png";
import publicAffairsIcon from "images/services_public_affairs.png";
import relationshipMappingIcon from "images/services_relationship_mapping.png";
import motivationalSpeakingIcon from "images/services_public_speaker.png";
import businessConsultancyIcon from "images/services_business_consultant.png";

const serviceData = [
  {
    name: "Community Engagement",
    desc: () => (
      <p>
      Tookes Strategic Group LLC offers a comprehensive community engagement service that is designed to help organizations build strong relationships with the communities they serve. We understand that community engagement is essential for organizations to establish trust, gain valuable feedback, and create a positive impact.
      </p>
    ),
    content: () => (
      <>
      <p>Our team of experienced consultants provides expertise in areas such as:</p>
      <div>
      <ul>
      <li>Community needs assessment</li>
      <li>Stakeholder mapping and engagement</li>
      <li>Community outreach and communication strategies</li>
      <li>Partnerships and collaboration initiatives</li>
      <li>Community events and workshops</li>
      <li>Public opinion research and analysis</li>
      <li>Crisis and issues management</li>
      </ul>
      </div>
      </>
    ),
    image: communityEngagementIcon,
  },
  {
    name: "Public Affairs",
    desc: () => (
      <p>
      Tookes Strategic Group LLC offers a comprehensive public affairs service that provides expert guidance and support to organizations seeking to navigate the complex landscape of government and public policy. Our public affairs service is designed to provide clients with a clear understanding of the political and regulatory environment and to help them develop and implement strategies that drive success.
      </p>
    ),
    content: () => (
      <>
      <p>Our team of experienced consultants provides expertise in areas such as:</p>
      <div>
      <ul>
      <li>Community needs assessment</li>
      <li>Stakeholder mapping and engagement</li>
      <li>Community outreach and communication strategies</li>
      <li>Partnerships and collaboration initiatives</li>
      <li>Community events and workshops</li>
      <li>Public opinion research and analysis</li>
      <li>Crisis and issues management</li>
      </ul>
      </div>
      </>
    ),
    image: publicAffairsIcon,
  },
  {
    name: "Relationship Mapping",
    desc: () => (
      <p>
      Tookes Strategic Group LLC offers a relationship mapping service that helps organizations identify and leverage their relationships with key stakeholders, including clients, partners, investors, and community members. Our relationship mapping service is designed to provide clients with a clear understanding of their network and to help them build stronger, more strategic relationships that support their goals.
      </p>
    ),
    content: () => (
      <>
      <p>Our team of experienced consultants provides expertise in areas such as:</p>
      <div>
      <ul>
      <li>Stakeholder identification and analysis</li>
      <li>Relationship strength assessment</li>
      <li>Relationship-building strategies and tactics</li>
      <li>Communication strategies and messaging</li>
      <li>Brand reputation and perception management</li>
      <li>Network visualization and analysis</li>
      <li>Influencer mapping and engagement</li>
      </ul>
      </div>
      </>
    ),
    image: relationshipMappingIcon,
  },
  {
    name: "Motivational Speaking",
    desc: () => (
      <p>
      Tookes Strategic Group LLC offers a motivational speaking service that provides inspiring and empowering talks to individuals and organizations seeking to overcome obstacles, deal with adversity, and achieve personal and professional growth. Our motivational speaking service is designed to provide clients with practical tools and insights that inspire action and drive results.
      </p>
    ),
    content: () => (
      <>
      <p>Our motivational speaking service features experienced speakers who specialize in areas such as:</p>
      <div>
      <ul>
      <li>Change management</li>
      <li>Growth mindset</li>
      <li>Overcoming obstacles</li>
      <li>Dealing with adversity</li>
      <li>Personal and professional development</li>
      <li>Leadership and team building</li>
      <li>Mindfulness and well-being</li>
      </ul>
      </div>
      </>
    ),
    image: motivationalSpeakingIcon,
  },
  {
    name: "Business Consultancy",
    desc: () => (
      <p>
      Tookes Strategic Group LLC offers a comprehensive business consultancy service that provides expert guidance and support to organizations seeking to improve their operations, grow their business, and achieve their goals. Our business consultancy service is designed to provide clients with a clear understanding of their business landscape and to help them develop and implement strategies that drive success.
      </p>
    ),
    content: () => (
      <>
      <p>Our team of experienced consultants provides expertise in areas such as:</p>
      <div>
      <ul>
      <li>Strategic planning and execution</li>
      <li>Business modeling and forecasting</li>
      <li>Market research and analysis</li>
      <li>Operational efficiency and optimization</li>
      <li>Utility service evaluation and optimization</li>
      <li>Financial analysis and management</li>
      <li>Business funding solutions</li>
      </ul>
      </div>
      </>
    ),
    image: businessConsultancyIcon,
  }
];

const ServiceItem = (props) => {
  const { name, desc, image, onClick } = props || {};
  
  return (
    <div className="col-xs-6 col-lg-6 row justify-content-center">
      <div className="image col-12">
        <img src={image} className="img-fluid" alt={name} />
      </div>
      <div className="col-12 text-center pb-3">
        <div className="h3">{name}</div>
        <p className="fs-6 lh-base my-4 text-start">{desc()}</p>
        <p className="text-center">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#serviceModal"
            onClick={() => onClick({ ...props })}
          >
            Learn More
          </button>
        </p>
      </div>
    </div>
  );
};

const ServiceList = ({ onClick }) => (
  serviceData.map((props, idx) => <ServiceItem key={idx} onClick={onClick} {...props} />)
);

export default function ServiceSection() {
  const [data, setData] = useState(null);
  const onClick = (event) => setData(event);
  const onClose = () => setData(null);

  return (
    <>
      <section id="services" className="white-section">
        <div className="container text-center">
          <p className="h2 display-5 fw-normal text-center">Our Services</p>
          <div className="row justify-content-center gx-5">
            <div className="col fs-5 lh-base my-4 ">
              Check out the services we offer. Reach out for further information
              and consultation.
            </div>
          </div>
          <div className="row py-5 gx-5">
            <ServiceList onClick={onClick} />
          </div>
        </div>
      </section>
      <ServiceModal onClick={onClose} { ...data} />
    </>
  );
}
