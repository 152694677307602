import React, { useEffect, useState } from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";

export default function Page({
  children,
  showHeader = true,
  mainClass = "site-content",
}) {
  return (
    <div
      className="page"
      data-testid="page"
      data-bs-spy="scroll"
      data-bs-target="#page-nav"
      data-bs-offset="0"
      tabIndex="0"
    >
      {showHeader && <Header />}
      <main id="main" className={mainClass}>
        {children}
      </main>
      <Footer />
    </div>
  );
}
