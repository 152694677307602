import React from "react";
import { useLocation } from "react-router-dom";

import parentLogo from "images/tsg_one_logo_small.png";
// import ParentLogo from "./ParentLogo";

export default function Header() {
  const location = useLocation();
  const publishUrl = "/#home";
  const NavLinks = [
    {
      title: "Home",
      hash: "#home",
    },
    {
      title: "Services",
      hash: "#services",
    },
    {
      title: "About Us",
      hash: "#about-us",
    },
    {
      title: "Contact Us",
      hash: "#contact",
    },
  ];
  const current = location.hash.replace(/#/, "");

  return (
    <div className="site-nav-header">
      {/* <div className="border-bottom mb-0 parent-header">
        <div className="container d-flex justify-content-center">
          <a
            className="nav-item"
            target="_blank"
            href={`${publishUrl}`}
            rel="noreferrer"
          >
            <ParentLogo />
          </a>
        </div>
      </div> */}
      <div id="page-nav">
        <nav className="main-nav-column-wrap navbar navbar-expand-md navbar-light">
          <div className="container justify-content-start">
            <div className="navbar-brand col-2 col-lg-1 px-1 py-1">
              <a
                href={`${publishUrl}`}
                rel="noreferrer"
              >
                {/* <ParentLogo /> */}
                <img
                  className="img-fluid"
                  src={parentLogo}
                  alt="Contact TSG-1.com"
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#site-main-navbar"
              aria-controls="site-main-navbar"
              aria-expanded="false"
              aria-label="toggle mobile navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="px-3 current-section">{current}</div>
            <div
              id="site-main-navbar"
              className="collapse navbar-collapse justify-content-md-center"
            >
              <ul className="nav navbar-nav nav-pills">
                {NavLinks.map((link, idx) => (
                  <li key={`nav-${idx}`} className="nav-item">
                    <a
                      key={`${link.title}= ${idx}`}
                      className={`nav-link ${
                        current.length && link.hash.includes(current) ? "active" : ""
                      }`}
                      data-bs-target={link.hash}
                      href={`${location.pathname}?${link.hash}`}
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
