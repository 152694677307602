import React from "react";
import Page from "components/PageWrapper";

import TopSection from "components/home/TopSection";
import AboutSection from "components/home/AboutSection";
import ServiceSection from "components/home/ServiceSection";
import ContactSection from "components/home/ContactSection";


export default function Home() {
  return (
    <Page>
      <TopSection />
      <ServiceSection />
      <AboutSection />
      <ContactSection />
    </Page>
  );
}
